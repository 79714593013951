import { OdinClient } from 'odinclient';
import { getClientId } from 'app/utils/helperFunctions';
import { WorkItemDto, PickListResponseDto, FileResponse, FileParameter, WorkItemLinkDto } from 'odinclient/build/main/lib/generated';

const odinClient = new OdinClient(getClientId(), process.env.REACT_APP_ENV);

export class OdinService {
    public getSearchedUser(user: string): Promise<any> {
        return odinClient.graphSearchUsers(user, 10, '2.0');
    }

    public submitWorkItem(workItemFields: object): Promise<any> {
        return odinClient.createWorkItem(undefined, 'Request', false, false, 'LLOIntake', '2.0', workItemFields);
    }

    public uploadAttachments(files: FileParameter[]) {
        return odinClient.uploadAttachmentsToAzureDevOps('LLOIntake', '2.0', files);
    }

    public linkAttachmentsToWorkItem(links: WorkItemLinkDto[]) {
        return odinClient.addLinksToWorkItem(false, 'LLOIntake', '2.0', links);
    }

    public getWorkItems(): Promise<WorkItemDto[]> {
        return odinClient.getWorkItems('GetMine', null, null, null, null, 'LLOIntake', 0, '2.0');
    }

    public isInRole(role: string): Promise<boolean> {
        return odinClient.isUserInRoles(role, '2.0');
    }

    public getPickList(id: string): Promise<PickListResponseDto[]> {
        return odinClient.getPickList(id, 'LLOIntake', '2.0');
    }

    public getAttachments(id: string): Promise<FileResponse> {
        return odinClient.downloadAttachmentsFromAzureDevOps(id, 'LLOIntake', '2.0');
    }
}