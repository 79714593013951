import * as React from 'react';

interface IAccessDeniedProps {}

const accessDenied: React.FunctionComponent<IAccessDeniedProps> = () => {
    return (
        <React.Fragment>
            <article className="accessDenied" role="alert">
                <div className="accessDeniedHeader">
                    Oops! <i className="ms-Icon ms-Icon--Sad" aria-hidden="true" />
                </div>
                <p>It looks like you are not yet authorized to access the Devices Logistics Request Form.</p>
                <p>
                    For assistance please contact the Devices Logistics Team at <a title="Contact Support" href="mailto:LLORequests@Microsoft.com" target="_blank" rel="noopener noreferrer">
                        LLORequests@Microsoft.com
                    </a>
                    .
                </p>
            </article>
        </React.Fragment>
    );
};

export default accessDenied;
