import 'office-ui-fabric-core/dist/css/fabric.css';

import {
    Icon,
    Image,
    initializeIcons,
    Nav,
    Persona,
    PersonaPresence,
    PersonaSize,
    Spinner,
    Text,
    ISpinnerStyles,
    INavStyles,
    IIconProps,
    INavButtonProps,
    IComponentAs
} from 'office-ui-fabric-react';
import React from 'react';
import { BrowserRouter, Link, Route, Switch } from 'react-router-dom';

import msftLogo from 'static/msftLogo.png';
import AccessDenied from 'app/components/AccessDenied/AccessDenied';
import CreateRequest from 'app/components/CreateRequest/CreateRequest';
import { EMPTY_USER, IUser, IUserManager, Default_Manager } from 'app/models/IUser';
import httpService from 'app/utils/httpService';
import { authProvider } from 'app/utils/authProvider';
import { AxiosResponse } from 'axios';
import AllRequests from 'app/components/AllRequests/AllRequests';
import { withAuthentication } from 'react-aad-msal';
import scssVariables from 'app/styles/variableExports.module.scss';
import { AppInsightsService } from 'app/utils/appInsights';
import { OdinService } from 'app/utils/odinService';

const appInsights = new AppInsightsService();
const odinService = new OdinService();
const userGuidePath = require('docs/LLO_User_Guide.pdf')

interface IAppState {
    currentUser: IUser;
    currentUserManager: IUserManager;
    isLoading: boolean;
    currentUserPhoto: any;
    hasAccess: boolean;
    remountKey: number;
    navHeight: string;
}

initializeIcons();

const spinnerStyles: ISpinnerStyles = {
    circle: {
        height: 150,
        width: 150,
        borderWidth: 10
    }
};

const navStyles: Partial<INavStyles> = {
    root: {
        backgroundColor: scssVariables.secondary,
        padding: 0,
        width: 180
    },
    groupContent: {
        marginBottom: 0
    },
    linkText: {
        color: scssVariables.white,
        fontSize: 16,
        fontWeight: '400'
    },
    compositeLink: {
        backgroundColor: scssVariables.secondary,
        paddingTop: 5,
        paddingBottom: 5
    },
    link: {
        backgroundColor: scssVariables.secondary,
        selectors: {
            '.ms-Nav-compositeLink:hover &': {
                backgroundColor: '#878787'
            }
        }
    }
};

const addIconProps: IIconProps = {
    iconName: 'CircleAddition',
    styles: {
        root: {
            color: scssVariables.white,
            marginLeft: '25px'
        }
    }
};

const filesIconProps: IIconProps = {
    iconName: 'DocumentSet',
    styles: {
        root: {
            color: scssVariables.white,
            marginLeft: '25px'
        }
    }
};

const documentIconProps: IIconProps = {
    iconName: 'DocumentSearch',
    styles: {
        root: {
            color: scssVariables.white,
            marginLeft: '25px'
        }
    }
}

export class App extends React.Component<any, IAppState> {
    constructor(props: any) {
        super(props);
        this.state = {
            currentUser: EMPTY_USER,
            currentUserManager: Default_Manager,
            isLoading: true,
            currentUserPhoto: null,
            hasAccess: true,
            remountKey: new Date().getTime(),
            navHeight: 'default-nav-height'
        };
    }

    public async componentDidMount() {
        await httpService
            .get('beta/me')
            .then((userResponse: AxiosResponse) => {
                if (userResponse.data) {
                    const user: IUser = userResponse.data;

                    appInsights.trackPageView();
                    appInsights.trackEvent(`${user.userPrincipalName} logging in.`);
                    appInsights.trackEvent(`Checking Odin permissions for ${user.userPrincipalName}`);

                    const startTime = performance.now();
                    odinService.isInRole('ValidUsers')
                        .then((isValid) => {
                            if (isValid) {
                                this.allowUserAccess(user);
                                appInsights.trackEvent(`${user.userPrincipalName} granted access to LLO`);
                            }
                            else {
                                this.denyUserAccess(user);
                            }
                        })
                        .catch((error) => {
                            appInsights.logException(error);
                            this.denyUserAccess(user);
                        });
                    const endTime = performance.now();

                    appInsights.trackEvent(`Odin permissions response time: ${(endTime - startTime)}`);

                    httpService
                        .get(`v1.0/me/photo/$value`, { responseType: 'blob' })
                        .then((photoResponse: AxiosResponse) => {
                            const imageUrl = URL.createObjectURL(photoResponse.data);
                            this.setState({
                                currentUserPhoto: imageUrl
                            });
                        })
                        .catch((error) => {
                            appInsights.logException(error);
                        });
                    httpService
                        .get('v1.0/me/manager')
                        .then((managerResponse: any) => {
                            const userManager: IUserManager = managerResponse.data;
                            if (userManager) {
                                this.setState({
                                    currentUserManager: userManager
                                });
                            }
                        })
                        .catch((error) => {
                            appInsights.logException(error);
                        });
                } else {
                    this.setState({
                        hasAccess: false,
                        isLoading: false
                    });
                }
            })
            .catch((error) => {
                appInsights.logException(error);
                this.setState({
                    hasAccess: false,
                    isLoading: false
                });
            });
    }

    public render() {
        return (
            <BrowserRouter>
                <div className="ms-Grid" dir="ltr">
                    <div className="ms-Grid-row">
                        <div className="header">
                            <Image height={30} src={msftLogo} className="msftLogo" alt="Microsoft Logo" />
                            <Text className="headerTitle">Devices Logistics Request</Text>
                            <div className="headerRightContainer">
                                <div className="navItemsRight">
                                    <Persona
                                        id="loginName"
                                        text={this.state.currentUser.displayName}
                                        size={PersonaSize.size40}
                                        presence={PersonaPresence.none}
                                        imageUrl={this.state.currentUserPhoto}
                                    />
                                    <a
                                        title="Data Privacy Notice"
                                        href="https://privacy.microsoft.com/en-US/data-privacy-notice"
                                        target="_blank"
                                        rel="noopener noreferrer">
                                        <i className="ms-Icon navIcon ms-Icon--LaptopSecure" />
                                    </a>
                                    <a
                                        title="Contact Support"
                                        href="mailto:DevicesHelp@Microsoft.com?subject=Requesting%20LLO%20Intake%20Application%20Assistance"
                                        target="_blank"
                                        rel="noopener noreferrer">
                                        <i className="ms-Icon navIcon ms-Icon--MailTentative" />
                                    </a>
                                    <i className="ms-Icon navIcon ms-Icon--Leave" onClick={this.logoutUser} title="Logout" />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="ms-Grid-row">
                        {this.state.isLoading ? (
                            <div className="loadingScreen">
                                <Spinner styles={spinnerStyles} />
                                <label aria-label="Submitting Request">Loading...</label>
                            </div>
                        ) : this.state.hasAccess ? (
                            this.userAuthorized()
                        ) : (
                            <AccessDenied />
                        )}
                    </div>
                </div>
            </BrowserRouter>
        );
    }

    private userAuthorized = () => {
        return (
            <React.Fragment>
                <Nav
                    className={`ms-Grid-col ms-sm12 ms-lg3 ms-xl2 no-padding ${this.state.navHeight}`}
                    linkAs={this.onRenderLink}
                    expandButtonAriaLabel="Expand or collapse"
                    styles={navStyles}
                    groups={[
                        {
                            links: [
                                {
                                    name: 'Create Request',
                                    url: '/',
                                    key: 'Create Request',
                                    iconProps: addIconProps
                                },
                                {
                                    name: 'My Requests',
                                    url: '/myrequests/',
                                    key: 'All Requests',
                                    iconProps: filesIconProps
                                },
                                {
                                    name: 'User Guide',
                                    url: '/',
                                    key: 'User Guide',
                                    iconProps: documentIconProps,
                                    target: '_blank'
                                }
                            ]
                        }
                    ]}
                />
                <div className={'ms-Grid-col ms-sm12 ms-lg9 msxl-10'}>
                    <Switch>
                        <Route path="/" exact>
                            <CreateRequest
                                key={this.state.remountKey}
                                currentUser={this.state.currentUser}
                                currentUserManager={this.state.currentUserManager}
                                refreshNav={this.refreshNav}
                            />
                        </Route>
                        <Route path="/myrequests" exact>
                            <AllRequests
                                key={this.state.remountKey}
                                currentUser={this.state.currentUser}
                                refreshNav={this.refreshNav}
                            />
                        </Route>
                    </Switch>
                </div>
            </React.Fragment>
        );
    };

    // custom component to make react-router-dom Link component work in fabric Nav
    private onRenderLink: IComponentAs<INavButtonProps> = (props: INavButtonProps) => {
        return (
            <Link
                className={props.className}
                style={{ color: 'inherit', boxSizing: 'border-box' }}
                to={props.href}
                onClick={() => this.onLinkClick(props)}>
                <span style={{ display: 'flex' }}>
                    {!!props.iconProps && <Icon {...props.iconProps} />}
                    {props.children}
                </span>
            </Link>
        );
    };

    private onLinkClick = (props: INavButtonProps) => {
        if (props.link.key === 'User Guide') {
            appInsights.trackEvent(`${this.state.currentUser.userPrincipalName} downloading application User Guide.`);

            const link = document.createElement('a');
            link.href = userGuidePath;
            link.setAttribute(
                'download',
                `LLO User Guide.pdf`,
            );
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);

            appInsights.trackEvent(`${this.state.currentUser.userPrincipalName} successfully downloaded application User Guide.`);
        }
        
        this.refreshCreateRequest()
    }

    // Rerenders CreateRequest component if sidebar CreateRequest link is clicked
    private refreshCreateRequest = () => {
        if (window.location.pathname === '/') {
            this.setState({ remountKey: new Date().getTime() });
        }
    };

    private logoutUser = () => {
        localStorage.clear();
        authProvider.logout();
    };

    private allowUserAccess = (user: IUser) => {
        this.setState({
            currentUser: user,
            hasAccess: true,
            isLoading: false
        });
    };

    private denyUserAccess = (user: IUser) => {
        appInsights.trackEvent(`User ${user.userPrincipalName} logged in, but was denied access`);
        this.setState({
            currentUser: user,
            hasAccess: false,
            isLoading: false
        });
    };

    // Refreshes side navbar to fit vertical size of component viewed
    public refreshNav = (activeComponent: string) => {
        let newNavHeight: string;
        switch (activeComponent) {
            case 'Loading':
            case 'CreateRequest':
            case 'Message':
            case 'AllRequests':
            case 'ViewLabRequest':
                newNavHeight = 'default-nav-height';
                break;
            case 'Lab':
                newNavHeight = 'height-970';
                break;
            case 'Logistics':
                newNavHeight = 'height-1690';
                break;
            case 'ViewLogisticsRequest':
                newNavHeight = 'height-1300';
                break;
        }
        this.setState({ navHeight: newNavHeight });
    };
}

export default withAuthentication(App, {
    provider: authProvider
});
