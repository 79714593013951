import { IDropdownOption, Spinner } from 'office-ui-fabric-react';
import * as React from 'react';

import LabForm from 'app/components/Forms/LabForm/LabForm';
import LogisticsForm from 'app/components/Forms/LogisticsForm/LogisticsForm';
import DropDownExtension from 'app/components/DropDown/DropDown';
import Message from 'app/components/Message/Message';
import { RequestType } from 'app/models/Constants/enums';
import { IUser, IUserManager } from 'app/models/IUser';

interface IRequestProps {
    currentUser: IUser;
    currentUserManager: IUserManager;
    refreshNav: (activeComponent: string) => void;
}

interface IRequestState {
    requestTypeOptions: Array<string>;
    requestTypeSelected: string;
    messageType: string;
    isLoading: boolean;
    workItemId: string;
    requestDateTime: Date;
    formSubmitted: boolean;
}

const spinnerStyles = {
    circle: {
        height: 150,
        width: 150,
        borderWidth: 10
    }
};

const initState: IRequestState = {
    requestTypeOptions: Object.values(RequestType),
    requestTypeSelected: undefined,
    messageType: undefined,
    isLoading: false,
    workItemId: undefined,
    requestDateTime: undefined,
    formSubmitted: false
};

export default class CreateRequest extends React.Component<IRequestProps, IRequestState> {
    constructor(props: IRequestProps) {
        super(props);
        this.props.refreshNav('CreateRequest');
        this.state = initState;
    }

    public render() {
        return (
            <div id="RequestPage">
                {this.state.isLoading ?
                    (<div id="creatingWorkItem">
                        <Spinner styles={spinnerStyles} />
                        <label aria-label="Submitting Request">Submitting Request...</label>
                    </div>) :
                    (<div>
                        {this.state.formSubmitted ? this.renderMessage() : this.renderForm()}
                    </div>)
                }
            </div>
        );
    }

    private renderMessage = () => {
        return (
            <Message
                type={this.state.messageType}
                workItemId={this.state.workItemId}
                requestDateTime={this.state.requestDateTime}
            />
        );
    };

    private renderForm = () => {
        let form;
        switch (this.state.requestTypeSelected) {
            case 'Lab':
                form = (
                    <LabForm
                        requestCategorySelected={this.state.requestTypeSelected}
                        messageTrigger={this.handleMessage}
                        isLoading={this.handleLoading}
                        currentUser={this.props.currentUser}
                        currentUserManager={this.props.currentUserManager}
                    />
                );
                break;
            case 'Logistics':
                form = (
                    <LogisticsForm
                        messageTrigger={this.handleMessage}
                        currentUser={this.props.currentUser}
                        isLoading={this.handleLoading}
                        requestCategorySelected={this.state.requestTypeSelected}
                        currentUserManager={this.props.currentUserManager}
                    />
                );
                break;
        }

        return (
            <React.Fragment>
                <h2>Create Request</h2>
                <div className="request-category-div">
                    <DropDownExtension
                        label="Request Category"
                        placeholder="Select Request Category"
                        dropDownOptions={this.state.requestTypeOptions}
                        required={true}
                        onChange={this.handleTypeChange}
                    />
                </div>
                {form}
            </React.Fragment>
        );
    };

    private handleMessage = (id: string, responseType: string) => {
        this.setState({
            messageType: responseType,
            workItemId: id,
            requestDateTime: new Date(),
            formSubmitted: true
        });
        this.props.refreshNav('Message');
    };

    private handleLoading = (_isLoading: boolean) => {
        this.setState({
            isLoading: _isLoading
        });
        this.props.refreshNav('Loading');
    };

    private handleTypeChange = async (event: React.FormEvent<HTMLDivElement>, selectedItem: IDropdownOption) => {
        await this.setState({ requestTypeSelected: selectedItem.text });
        this.props.refreshNav(this.state.requestTypeSelected);
    };
}
