import * as React from 'react';
import {
    getTheme,
    mergeStyleSets,
    FontWeights,
    Modal,
    IconButton,
    IIconProps,
    PrimaryButton,
    Spinner,
    ISpinnerStyles
} from 'office-ui-fabric-react';
import { IRequestRelation, IFileResponseMap } from 'app/models/IRequestDto';
import { OdinService } from 'app/utils/odinService';
import { FileResponse } from 'odinclient/build/main/lib/generated';
import { getAttachmentGuid } from 'app/utils/helperFunctions';
import { refreshTokens } from 'app/utils/httpService';
import { AppInsightsService } from 'app/utils/appInsights';
import { IUser } from 'app/models/IUser';

interface IModalProps {
    requestRelation: IRequestRelation;
    currentUser: IUser;
}

interface IModalState {
    attachmentList: IFileResponseMap[];
    isDownloading: boolean;
    isModalOpen: boolean;
}

const odinService = new OdinService();
const appInsights = new AppInsightsService();
const titleId = 'Attachments';

export default class AttachmentsModal extends React.Component<IModalProps, IModalState> {
    constructor(props: IModalProps) {
        super(props);
        this.state = {
            attachmentList: [],
            isModalOpen: false,
            isDownloading: false
        };
    }

    public render() {
        return (
            <div className={contentStyles.button}>
                <PrimaryButton onClick={() => this.toggleModal(true)} text="View Attachments" />
                <Modal
                    titleAriaId={titleId}
                    isOpen={this.state.isModalOpen}
                    onDismiss={() => this.toggleModal(false)}
                    isBlocking={false}
                    containerClassName={contentStyles.container}
                >
                    <div className={contentStyles.header}>
                        <span>Attachments</span>
                        <IconButton
                            styles={iconButtonStyles}
                            iconProps={cancelIcon}
                            ariaLabel="Close"
                            onClick={() => this.toggleModal(false)}
                        />
                    </div>

                    {
                        this.state.isDownloading
                            ?
                            <div className="loadingScreen">
                                <Spinner styles={spinnerStyles} />
                            </div>
                            :
                            <div className={contentStyles.body}>
                                <div>
                                    {
                                        this.props.requestRelation.attachmentMaps.length > 0
                                            ?
                                            this.renderAttachments()
                                            :
                                            <div>
                                                <h4>No Attachments Found</h4>
                                            </div>
                                    }
                                </div>
                            </div>
                    }
                </Modal>
            </div>
        );
    }

    private renderAttachments() {
        return (
            <div>
                <table>
                    <tbody>
                        {
                            this.props.requestRelation.attachmentMaps.map(relation => {
                                return (
                                    <tr key={relation.fileId}>
                                        <td className={contentStyles.fileNames}>{relation.fileName}</td>
                                        <td>
                                            <IconButton
                                                styles={iconButtonStyles}
                                                iconProps={downloadIcon}
                                                ariaLabel="Download"
                                                onClick={() => this.downloadAttachment(relation)}
                                            />
                                        </td>
                                    </tr>
                                )
                            })
                        }
                    </tbody>
                </table>
            </div>
            )
    }

    private downloadAttachment = (fileRelation: IFileResponseMap) => {
        this.setState({ isDownloading: true });
        const attachmentGuid = getAttachmentGuid(fileRelation.fileId);
        refreshTokens()
            .then(() => {
                appInsights.trackEvent(`${this.props.currentUser.userPrincipalName} starting download for attachment #${attachmentGuid}`);
                const startTime = performance.now();
                odinService.getAttachments(attachmentGuid)
                    .then((result: FileResponse) => {
                        if (result.data) {
                            let url = window.URL.createObjectURL(result.data);
                            let downloadLink = document.createElement('a');
                            downloadLink.href = url;
                            downloadLink.setAttribute(
                                'download',
                                fileRelation.fileName,
                            );
                            document.body.appendChild(downloadLink);
                            downloadLink.click();
                            document.body.removeChild(downloadLink);

                            appInsights.trackEvent(`${this.props.currentUser.userPrincipalName} successfully downloaded attachment #${attachmentGuid}`);
                        }
                        else {
                            appInsights.trackEvent(`No attachment data returned for attachment id ${attachmentGuid} from Odin`);
                        }

                        this.setState({ isDownloading: false });
                    })
                    .catch((error) => {
                        appInsights.logException(error);
                        appInsights.trackEvent(`Failed to get attachment from Odin. Details: ${error}`);
                        this.setState({ isDownloading: false });
                    })
                const endTime = performance.now();
                appInsights.trackEvent(`Odin attachment response time: ${(endTime - startTime)}`);
            })
            .catch((error) => {
                appInsights.logException(error);
                appInsights.trackEvent(`Could not refresh tokens for ${this.props.currentUser.userPrincipalName}. Details: ${error}`);
                this.setState({ isDownloading: false });
            });
    }

    private toggleModal = (toggle: boolean) => {
        this.setState({ isModalOpen: toggle })
	}
};

const downloadIcon: IIconProps = { iconName: 'Installation' };
const cancelIcon: IIconProps = { iconName: 'Cancel' };

const theme = getTheme();
const iconButtonStyles = {
    root: {
        color: theme.palette.themePrimary,
        marginLeft: 'auto',
        marginTop: '4px',
        marginRight: '2px',
    },
    rootHovered: {
        color: theme.palette.neutralDark,
    }
};

const contentStyles = mergeStyleSets({
    container: {
        display: 'flex',
        flexFlow: 'column nowrap',
        alignItems: 'stretch',
    },
    header: [
        theme.fonts.xLargePlus,
        {
            flex: '1 1 auto',
            borderTop: `4px solid ${theme.palette.themePrimary}`,
            color: theme.palette.neutralPrimary,
            display: 'flex',
            alignItems: 'center',
            fontWeight: FontWeights.semibold,
            padding: '12px 12px 14px 24px',
        },
    ],
    body: {
        flex: '4 4 auto',
        padding: '0 24px 24px 24px',
        overflowY: 'hidden',
    },
    fileNames: [
        theme.fonts.medium,
        {
            fontSize: '17px',
            padding: '0 12px 0 0'
        }
    ],
    button: {
        padding: '10px 10px 10px 0'
    }
});

const spinnerStyles: ISpinnerStyles = {
    circle: {
        height: 50,
        width: 50,
        borderWidth: 3
    }
};