import { Label, DefaultButton, IconButton } from 'office-ui-fabric-react';
import * as React from 'react';
import { useDropzone } from 'react-dropzone';
import {
    DetailsList,
    DetailsListLayoutMode,
    IColumn,
    SelectionMode,
    ConstrainMode
} from 'office-ui-fabric-react/lib/DetailsList';
import scssVariables from 'app/styles/variableExports.module.scss';

// the IDocument interface is simply a file with metadata (a timestamped key and stringified filesize)
export interface IDocument {
    key: string;
    fileSize: string;
    file: File;
}

export interface IDropZoneProps {
    _handleDropFile: any;
    _handleRemoveFile: any;
    acceptedDocuments: IDocument[];
}

export const DropZone: React.FunctionComponent<IDropZoneProps> = ({ _handleDropFile, _handleRemoveFile, acceptedDocuments }) => {
    const { getRootProps, getInputProps, open } = useDropzone({
        noClick: true,
        noKeyboard: true,
        maxSize: 1e8, // 100mb
        multiple: true,
        onDrop: _handleDropFile
    });

    const columns: IColumn[] = [
        {
            key: 'column1',
            name: 'File Name',
            fieldName: 'name',
            minWidth: 210,
            maxWidth: 450,
            isRowHeader: true,
            isResizable: true,
            isSortedDescending: false,
            data: 'string',
            isPadded: true,
            onRender: (item: IDocument) => {
                return <span>{item.file.name}</span>;
            }
        },
        {
            key: 'column2',
            name: 'Size',
            fieldName: 'fileSizeRaw',
            minWidth: 70,
            maxWidth: 110,
            isResizable: true,
            data: 'number',
            onRender: (item: IDocument) => {
                return <span>{item.fileSize}</span>;
            },
            isPadded: true
        },
        {
            key: 'column3',
            name: 'Remove',
            minWidth: 70,
            maxWidth: 90,
            isResizable: false,
            isCollapsible: false,
            onRender: (item: IDocument) => {
                return (
                    <IconButton
                        id={item.key}
                        onClick={() => { _handleRemoveFile(item.key); }}
                        iconProps={{ 
                            iconName: 'Delete',
                            styles: { root: { color: scssVariables.danger }}
                        }}
                        ariaLabel="Remove"
                        styles={{ root: { height: 14 }}}
                    />
                );
            },
            isPadded: true
        }
    ];

    return (
        <React.Fragment>
            <Label htmlFor="attachments" className="attachments-label">
                Attachments {acceptedDocuments.length > 0 ? `(${acceptedDocuments.length})` : null}
            </Label>
            <div {...getRootProps({ className: 'attachments', id: 'DropZone' })} data-testid="dropZone">
                <DefaultButton
                    className="browse-button"
                    onClick={open}
                    text="Browse... "
                    iconProps={{ iconName: 'FolderHorizontal' }}
                />
                <input {...getInputProps()} />
                {acceptedDocuments.length < 1 ? (
                    <div className="dropZone">
                        <div className="dropZone-label">Drag files here to attach or click the Browse button above</div>
                    </div>
                ) : (
                    <DetailsList
                        items={acceptedDocuments}
                        columns={columns}
                        constrainMode={ConstrainMode.unconstrained}
                        selectionMode={SelectionMode.none}
                        layoutMode={DetailsListLayoutMode.justified}
                        isHeaderVisible={true}
                    />
                )}
            </div>
        </React.Fragment>
    );
};

export default DropZone;
