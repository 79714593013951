import { Dropdown, IDropdownOption } from 'office-ui-fabric-react';
import * as React from 'react';

interface IDropdownProps {
    id?: string;
    dropDownOptions: Array<string>;
    required: boolean;
    placeholder: string;
    label: string;
    onChange: (event: React.FormEvent<HTMLDivElement>, option?: IDropdownOption, index?: number) => void;
}

interface IDropDownState {
    displayOptions: Array<any>;
}

export default class DropDownExtension extends React.Component<IDropdownProps, IDropDownState> {
    constructor(props: IDropdownProps) {
        super(props);
        this.state = {
            displayOptions: []
        };
        this.populateDropDown();
    }

    componentDidUpdate(prevProps: IDropdownProps) {
        if (this.props.dropDownOptions !== prevProps.dropDownOptions) {
            this.populateDropDown();
        }
    }

    public render() {
        const { dropDownOptions, ...dropDownProps } = this.props;
        return (
            <React.Fragment>
                <Dropdown {...dropDownProps} options={this.state.displayOptions} />
            </React.Fragment>
        );
    }

    private populateDropDown = () => {
        this.props.dropDownOptions.map((data: string, index: number) => {
            this.state.displayOptions.push({ key: index, text: data });
            return null;
        });
    };
}
