import {
    IDropdownOption,
    PrimaryButton,
    TextField,
    DatePicker,
    ChoiceGroup,
    IChoiceGroupOption,
    TeachingBubble,
    IButtonProps,
    MessageBar,
    MessageBarType
} from 'office-ui-fabric-react';
import * as React from 'react';
import { RequestType } from 'app/models/Constants/enums';
import { OdinService } from 'app/utils/odinService';
import { AppInsightsService } from 'app/utils/appInsights';
import {
    getAreaPath,
    IsNullOrWhiteSpace,
    convertFileSizeToString,
    renderFormSection,
    getPickListId
} from 'app/utils/helperFunctions';
import DropDownExtension from 'app/components/DropDown/DropDown';
import { DropZone, IDocument } from 'app/components/DropZone/DropZone';
import { IUser, IUserManager } from 'app/models/IUser';
import { PickListResponseDto, FileParameter, LinkType } from 'odinclient/build/main/lib/generated';
import { refreshTokens } from 'app/utils/httpService';

const odinService = new OdinService();
const appInsights = new AppInsightsService();
const shippingSpreadsheet = require('docs/International_Shipping_Spreadsheet.xlsx')

interface IRequestProps {
    requestCategorySelected: string;
    messageTrigger?: (result: string, message: string) => void;
    isLoading?: (isLoading: boolean) => void;
    currentUser: IUser;
    currentUserManager: IUserManager;
}

interface IRequestState {
    // Request Information
    requestTypeOptions: Array<string>;
    requestTypeSelected: string;
    requestTitle: string;
    approver: string;
    requestDescription: string;
    location: string;
    pickupDate: Date;
    // Package Information
    quantity: number;
    weight: number;
    palletizedOptionSelected: boolean;
    dimensions: string;
    // Shipping Information
    exportTypes: Array<string>;
    exportTypeSelected: string;
    intendedUse: string;
    retailMarketValue: string;
    exportReason: string;
    confidential: boolean;
    conditionSelected: string;
    batterySelected: boolean;
    liquidSelected: boolean;
    companyName: string;
    address: string;
    postalCode: string;
    receiverName: string;
    receiverNumber: string;
    // Attachments
    documentsForUpload: IDocument[];
    // Other Misc State
    currentAreaPath: string;
    isWarning: boolean;
    // Teaching Bubble Notifications
    teachingBubbleVisible: boolean;
    teachingBubbleTarget: string;
    teachingBubbleMessage: string;
}

const initState = {
    // Request Information
    requestTypeOptions: ['Shipping Request', 'Move Request', 'Event Support'],
    requestTypeSelected: undefined,
    requestTitle: undefined,
    requestDescription: undefined,
    location: undefined,
    pickupDate: undefined,
    // Package Information
    quantity: undefined,
    weight: undefined,
    palletizedOptionSelected: undefined,
    dimensions: undefined,
    // Shipping Information
    exportTypes: [],
    exportTypeSelected: undefined,
    intendedUse: undefined,
    retailMarketValue: undefined,
    exportReason: undefined,
    confidential: undefined,
    conditionSelected: undefined,
    batterySelected: undefined,
    liquidSelected: undefined,
    companyName: undefined,
    address: undefined,
    postalCode: undefined,
    receiverName: undefined,
    receiverNumber: undefined,
    // Other Misc State
    documentsForUpload: [],
    currentAreaPath: getAreaPath(),
    isWarning: false,
    // Teaching Bubble Notifications
    teachingBubbleVisible: undefined,
    teachingBubbleTarget: undefined,
    teachingBubbleMessage: undefined
};

const conditionOptions: IChoiceGroupOption[] = [
    { key: 'New', text: 'New' },
    { key: 'Used', text: 'Used' }
];

const truthyOptions: IChoiceGroupOption[] = [
    { key: 'yes', text: 'Yes' },
    { key: 'no', text: 'No' }
];

export default class LogisticsForm extends React.Component<IRequestProps, IRequestState> {
    _isMounted = false;

    constructor(props: IRequestProps) {
        super(props);
        this.state = {
            ...initState,
            approver: this.props.currentUserManager.userPrincipalName
        };
    }

    public async componentDidMount() {
        this._isMounted = true;
        appInsights.trackPageView();
        const startTime = performance.now();
        await odinService.getPickList(getPickListId())
            .then((pickList: PickListResponseDto[]) => {
                if (pickList.length > 0) {
                    this._isMounted && this.setState({
                        exportTypes: pickList[0].items
                    })
                }
            })
            .catch((error) => {
                appInsights.logException(error)
            })
        const endTime = performance.now();
        appInsights.trackEvent(`Odin PickList response time: ${(endTime - startTime)}`);
    }

    public async componentWillUnmount() {
        this._isMounted = false;
    }

    private isFormValid = (): boolean => {
        return (
            !IsNullOrWhiteSpace(this.state.requestTitle) &&
            !IsNullOrWhiteSpace(this.state.requestDescription) &&
            this.state.requestTypeSelected !== undefined &&
            this.state.pickupDate !== undefined &&
            this.state.exportTypeSelected !== undefined &&
            !IsNullOrWhiteSpace(this.state.intendedUse) &&
            this.state.retailMarketValue !== undefined &&
            this.state.conditionSelected !== undefined &&
            this.state.batterySelected !== undefined &&
            !this.state.liquidSelected &&
            this.state.confidential !== undefined &&
            !IsNullOrWhiteSpace(this.state.location) &&
            !IsNullOrWhiteSpace(this.state.address) &&
            !IsNullOrWhiteSpace(this.state.companyName) &&
            !IsNullOrWhiteSpace(this.state.receiverName) &&
            !IsNullOrWhiteSpace(this.state.receiverNumber) &&
            !IsNullOrWhiteSpace(this.state.approver) &&
            this.props.currentUser !== undefined
        );
    };

    private bubbleButtonProps = (): IButtonProps => {
        const teachingBubbleButtonProps: IButtonProps = {
            children: 'Dismiss',
            onClick: () => this.setState({
                teachingBubbleVisible: false,
                teachingBubbleTarget: undefined,
                teachingBubbleMessage: undefined
            })
        }

        return teachingBubbleButtonProps;
    }

    private handleChoiceGroupChange = (event: React.FormEvent<HTMLElement | HTMLInputElement>, fieldName: string, option?: IChoiceGroupOption) => {
        const partialState = {};
        if (fieldName === 'conditionSelected') {
            partialState[fieldName] = option.text;
            this.setState(partialState);
        } else {
            partialState[fieldName] = option.text === 'Yes';
            this.setState(partialState);
        }
    };

    private handlePickupDateChange = (date) => {
        this.setState({ pickupDate: date });
    };

    private handleDropDownChange = (event: React.FormEvent<HTMLDivElement>, fieldName: string, fieldValue?: IDropdownOption) => {
        const partialState = {};
        partialState[fieldName] = fieldValue.text;
        this.setState(partialState);

        if (fieldName === 'exportTypeSelected') {
            if (fieldValue.text === 'Other') {
                this.setState({
                    teachingBubbleVisible: true,
                    teachingBubbleTarget: '#ShippingType',
                    teachingBubbleMessage: 'Please specify in further detail in the "International Shipping Spreadsheet" below.'
                    });
            }
            else {
                this.setState({
                    teachingBubbleVisible: false,
                    teachingBubbleMessage: undefined,
                    teachingBubbleTarget: undefined
                })
            }
        }
    };

    private handleTextFieldChange = (
        event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
        fieldName: string,
        fieldValue?: string,
    ) => {
        const partialState = {};
        if (fieldName === 'quantity' || fieldName === 'weight') {
            partialState[fieldName] = Number.parseInt(fieldValue);
            this.setState(partialState);
        }
        else if (fieldName === 'dimensions') {
            const regex = new RegExp('[^0-9xX.,]');
            if (fieldValue.search(regex) > -1) {
                this.setState({ dimensions: undefined })
            }
            else {
                this.setState({ dimensions: fieldValue })
            }
        }
        else {
            partialState[fieldName] = fieldValue;
            this.setState(partialState);
        }
    };

    private handleDropFile = (droppedFiles: File[], rejectedFiles: File[]) => {
        const timestamp = new Date();
        let newlyAcceptedDocuments = droppedFiles.map((file) => {
            const doc: IDocument = {
                key: file.name + timestamp.getSeconds().toString() + timestamp.getMilliseconds().toString(),
                fileSize: convertFileSizeToString(file.size),
                file: file
            };
            return doc;
        });
        this.setState({ documentsForUpload: [...this.state.documentsForUpload, ...newlyAcceptedDocuments] });
        if (rejectedFiles.length > 0) {
            this.setState({
                teachingBubbleVisible: true,
                teachingBubbleTarget: '#DropZone',
                teachingBubbleMessage: 'Files may not exceed 100MB.'
            });
        }
    };

    private handleRemoveFile = (removedFileKey: string) => {
        this.setState({
            documentsForUpload: this.state.documentsForUpload.filter((file) => {
                return file.key !== removedFileKey;
            })
        });
    };

    private handleFormSubmissionError(appInsights: AppInsightsService, error) {
        appInsights.logException(error);
        appInsights.trackEvent(`${this.props.currentUser.userPrincipalName} failed to submit a Logistcs Request. Details: ${error}`);
        this.props.isLoading(false);
        this.props.messageTrigger(null, 'failure');
    }

    private handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        this.props.isLoading(true);
        refreshTokens()
            .then(() => {
                appInsights.trackEvent(`${this.props.currentUser.userPrincipalName} submitting a Logistics Request`);
                const requestStart = performance.now();
                odinService
                    .submitWorkItem(this.getWorkitemFields())
                    .then((_workItemId) => {
                        appInsights.trackEvent(`${this.props.currentUser.userPrincipalName} created work item #${_workItemId}`);
                        if (this.state.documentsForUpload.length > 0) {
                            let files: FileParameter[] = this.state.documentsForUpload.map(doc => {
                                const fileParameter: FileParameter = {
                                    fileName: doc.file.name,
                                    data: doc.file
                                };
                                return fileParameter;
                            });
                            appInsights.trackEvent(`Attaching files to work item #${_workItemId}`);
                            const fileUploadStart = performance.now();
                            odinService
                                .uploadAttachments(files)
                                .then((result) => {
                                    let links = [];
                                    result.forEach((AttachmentDto) => {
                                        const workItemLink = {
                                            id: AttachmentDto.id,
                                            type: LinkType.AttachedFile,
                                            url: AttachmentDto.url,
                                            comment: 'Attached via LLO Request Intake',
                                            name: AttachmentDto.fileName,
                                            workItemId: _workItemId
                                        };
                                        links.push(workItemLink);
                                    });
                                    odinService
                                        .linkAttachmentsToWorkItem(links)
                                        .then(() => {
                                            this.props.isLoading(false);
                                            this.props.messageTrigger(_workItemId, 'success');
                                        })
                                        .catch((error) => {
                                            appInsights.logException(error);
                                            this.props.isLoading(false);
                                            this.props.messageTrigger(_workItemId, 'failure');
                                            this.handleFormSubmissionError(appInsights, error);
                                        });
                                })
                                .catch((error) => {
                                    appInsights.trackEvent(`Failed to upload attachments for work item #${_workItemId}`);
                                    appInsights.logException(error);
                                    this.props.isLoading(false);
                                    this.props.messageTrigger(_workItemId, 'failure');
                                    this.handleFormSubmissionError(appInsights, error);
                                });
                            const fileUploadEnd = performance.now();
                            appInsights.trackEvent(`File upload for work item #${_workItemId} response time: ${(fileUploadEnd - fileUploadStart)}`);
                        } else {
                            this.props.isLoading(false);
                            this.props.messageTrigger(_workItemId, 'success');
                            appInsights.trackEvent(`Logistis work item #${_workItemId} created`, RequestType.Logistics);
                        }
                    })
                    .catch((error) => {
                        appInsights.trackEvent(`${this.props.currentUser.userPrincipalName} failed to submit a Logistics request`);
                        appInsights.logException(error);
                        this.props.isLoading(false);
                        this.props.messageTrigger(null, 'failure');
                        this.handleFormSubmissionError(appInsights, error);
                    });
                const requestEnd = performance.now();
                appInsights.trackEvent(`Logistics request submit response time: ${(requestEnd - requestStart)}`);
            })
            .catch((error) => {
                appInsights.logException(error);
                this.props.isLoading(false);
                this.props.messageTrigger(null, 'failure');
                this.handleFormSubmissionError(appInsights, error);
            });
        event.preventDefault();
    };

    private getWorkitemFields() {
        return {
            "System.AreaPath": this.state.currentAreaPath,
            'Microsoft.Custom.RequestCategory': this.props.requestCategorySelected,
            'System.AssignedTo': 'pending@microsoft.com',
            'Microsoft.Custom.Surface.RequestorName': this.props.currentUser.userPrincipalName,
            'Microsoft.Custom.QualityApproverName': this.state.approver,
            'Microsoft.Custom.Surface.RequestType': this.state.requestTypeSelected,
            'System.Title': this.state.requestTitle,
            'System.Description': this.state.requestDescription,
            'Microsoft.Custom.DropLocation': this.state.location,
            'Microsoft.Custom.RequestedStartDate': this.state.pickupDate,
            'Microsoft.Custom.Surface.Customername': this.state.receiverName,
            'Microsoft.Custom.ReceiverPhone': this.state.receiverNumber,
            'Microsoft.Custom.NumberOfPieces': this.state.quantity,
            'Microsoft.Custom.Weight': this.state.weight,
            'Microsoft.Custom.Palletized': this.state.palletizedOptionSelected,
            'Microsoft.Custom.Dimensions': this.state.dimensions,
            'Custom.ExportType': this.state.exportTypeSelected,
            'Microsoft.Custom.IntendedUse': this.state.intendedUse,
            'Microsoft.Custom.ExportReason': this.state.exportReason,
            'Microsoft.Custom.Confidential': this.state.confidential,
            'Microsoft.Custom.Condition': this.state.conditionSelected,
            'Microsoft.Custom.BatteryIncluded': this.state.batterySelected,
            'Microsoft.Custom.LiquidIncluded': this.state.liquidSelected,
            'Microsoft.Custom.CompanyName': this.state.companyName,
            'Microsoft.Custom.ShipToAddress': this.state.address,
            'Microsoft.Custom.PostalCode': this.state.postalCode,
            'Microsoft.VSTS.Common.ValueArea': this.state.retailMarketValue
        };
    }

    public render() {
        const requestInformationInputs = [
            [
                <DropDownExtension
                    label="Request Type"
                    placeholder="Select Request Type"
                    dropDownOptions={this.state.requestTypeOptions}
                    required={true}
                    onChange={(event, newValue) => this.handleDropDownChange(event, 'requestTypeSelected', newValue)}
                />,
                <TextField
                    label="Requestor"
                    iconProps={{ iconName: 'ProfileSearch' }}
                    value={this.props.currentUser.displayName}
                    disabled
                    required
                    readOnly
                />,
                <TextField
                    label="Approver"
                    placeholder="Enter Approvers"
                    defaultValue={this.state.approver}
                    onChange={(event, newValue) => this.handleTextFieldChange(event, 'approver', newValue)}
                    required
                />
            ],
            [
                <TextField
                    label="Title"
                    onChange={(event, newValue) => this.handleTextFieldChange(event, 'requestTitle', newValue)}
                    placeholder="Title your request"
                    required
                />
            ],
            [
                <TextField
                    label="Request Description"
                    onChange={(event, newValue) => this.handleTextFieldChange(event, 'requestDescription', newValue)}
                    placeholder="Describe your request in as much detail as possible"
                    multiline
                    rows={5}
                    autoAdjustHeight
                    required
                />
            ],
            [
                <TextField
                    label="Drop Off Location"
                    onChange={(event, newValue) => this.handleTextFieldChange(event, 'location', newValue)}
                    placeholder="Building or Lab Location"
                    required
                />,
                <DatePicker
                    value={this.state.pickupDate}
                    label="Pickup Date"
                    onSelectDate={this.handlePickupDateChange}
                    placeholder="Select date"
                    minDate={new Date()}
                    isRequired
                />,
                <React.Fragment />
            ]
        ];

        const packageInformationInputs = [
            [
                <TextField
                    type="number"
                    label="Number of Pieces"
                    placeholder="Enter number"
                    onChange={(event, newValue) => this.handleTextFieldChange(event, 'quantity', newValue)}
                />,
                <ChoiceGroup
                    label="Palletized?"
                    className="choiceGroup-inline-flex"
                    options={truthyOptions}
                    onChange={(event, newValue) => this.handleChoiceGroupChange(event, 'palletizedOptionSelected', newValue)}
                />,
                <React.Fragment>
                    {
                        this.state.teachingBubbleVisible
                            ? <TeachingBubble
                                target={this.state.teachingBubbleTarget}
                                primaryButtonProps={this.bubbleButtonProps()}
                                onDismiss={() => this.setState({ teachingBubbleVisible: false, teachingBubbleMessage: undefined, teachingBubbleTarget: undefined })}
                            >{this.state.teachingBubbleMessage}</TeachingBubble>
                            : null
                    }
                </React.Fragment>,
            ],
            [
                <TextField
                    type="number"
                    label="Weight"
                    onChange={(event, newValue) => this.handleTextFieldChange(event, 'weight', newValue)}
                    placeholder="Enter Weight in Lbs"
                />,
                <TextField
                    label="Dimensions (inches)"
                    onChange={(event, newValue) => this.handleTextFieldChange(event, 'dimensions', newValue)}
                    placeholder="Enter L x W x D in inches"
                />,
                <React.Fragment>
                    {this.state.dimensions ? null
                        : <MessageBar messageBarType={MessageBarType.error}>Please enter dimensions in 'LxWxH' format. Ex: '1x2x3'</MessageBar>
                    }
                </React.Fragment>
            ]
        ];

        const shippingInformationInputs = [
            [
                <DropDownExtension
                    id="ShippingType"
                    label="Shipping Type"
                    placeholder="Select Shipping Type"
                    dropDownOptions={this.state.exportTypes}
                    required={true}
                    onChange={(event, newValue) => this.handleDropDownChange(event, 'exportTypeSelected', newValue)}
                />,
                <TextField
                    label="Intended Use"
                    placeholder="Enter use"
                    onChange={(event, newValue) => this.handleTextFieldChange(event, 'intendedUse', newValue)}
                    required
                />,
                <TextField
                    label="Retail/Market Value Per Piece"
                    onChange={(event, newValue) => this.handleTextFieldChange(event, 'retailMarketValue', newValue)}
                    placeholder="Enter Value in USD"
                    required
                />
            ],
            [
                <MessageBar>
                    <b>
                        Download this
                        <a href={shippingSpreadsheet} download="International_Shipping_Spreadsheet">
                        International Shipping Spreadsheet
                        </a>
                        , fill out all relevant fields, and upload it as an attachment below.
                    </b>
                </MessageBar>
            ],
            [
                <ChoiceGroup
                    label="Confidential?"
                    className="choiceGroup-inline-flex"
                    options={truthyOptions}
                    onChange={(event, newValue) => this.handleChoiceGroupChange(event, 'confidential', newValue)}
                    required
                />,
                <ChoiceGroup
                    label="Condition"
                    className="choiceGroup-inline-flex"
                    options={conditionOptions}
                    onChange={(event, newValue) => this.handleChoiceGroupChange(event, 'conditionSelected', newValue)}
                    required
                />,
                <React.Fragment />
            ],
            [
                <ChoiceGroup
                    label="Battery Included?"
                    className="choiceGroup-inline-flex"
                    options={truthyOptions}
                    onChange={(event, newValue) => this.handleChoiceGroupChange(event, 'batterySelected', newValue)}
                    required
                />,
                <ChoiceGroup
                    className="choiceGroup-inline-flex"
                    label="Liquid Included?"
                    options={truthyOptions}
                    onChange={(event, newValue) => this.handleChoiceGroupChange(event, 'liquidSelected', newValue)}
                    required
                />,
                <React.Fragment>
                    {this.state.liquidSelected ? (
                        <MessageBar messageBarType={MessageBarType.error}>Cannot ship liquids</MessageBar>
                        ) : null }
                </React.Fragment>
            ],
            [
                <TextField
                    label="Company Name"
                    placeholder="Enter company name"
                    onChange={(event, newValue) => this.handleTextFieldChange(event, 'companyName', newValue)}
                    required
                />
            ],
            [
                <TextField
                    label="Address"
                    placeholder="Enter address"
                    onChange={(event, newValue) => this.handleTextFieldChange(event, 'address', newValue)}
                    required
                />
            ],
            [
                <TextField
                    label="Postal Code"
                    placeholder="Enter postal code"
                    onChange={(event, newValue) => this.handleTextFieldChange(event, 'postalCode', newValue)}
                    required
                />,
                <TextField
                    label="Receiver Name"
                    placeholder="Enter receiver name"
                    onChange={(event, newValue) => this.handleTextFieldChange(event, 'receiverName', newValue)}
                    required
                />,
                <TextField
                    label="Receiver Phone Number"
                    placeholder="Enter receiver phone number"
                    onChange={(event, newValue) => this.handleTextFieldChange(event, 'receiverNumber', newValue)}
                    required
                />
            ]
        ];
    
        const attachmentsInputs = [
            [
                <DropZone
                    _handleDropFile={this.handleDropFile}
                    _handleRemoveFile={this.handleRemoveFile}
                    acceptedDocuments={this.state.documentsForUpload}
                />
            ]
        ];

        return (
            <form onSubmit={this.handleSubmit}>
                {renderFormSection('Request Information', requestInformationInputs)}
                {renderFormSection('Package Information', packageInformationInputs)}
                {renderFormSection('Shipping Information', shippingInformationInputs)}
                {renderFormSection('Attachments', attachmentsInputs)}
                <div className="ms-Grid-row">
                    <PrimaryButton type="submit" text="Submit Request" style={{marginTop: 15, padding: 10}} disabled={!this.isFormValid()} />
                </div>
            </form>
        )
    };
}
