import * as React from 'react';
import { getClientId } from 'app/utils/helperFunctions';

export interface IMessageProps {
    type: string;
    workItemId?: string;
    requestDateTime: Date;
}

const message: React.FunctionComponent<IMessageProps> = (props) => {
    let renderMessage;

    switch (props.type) {
        case 'success':
            renderMessage = (
                <div className="message">
                    <div className="message-box success-background">
                        <div className="message-header success">Success!</div>
                        <div className="message-box-content">{`Request '${props.workItemId}' was successfully created and the LLO team has been notified.`}</div>
                    </div>
                    <p>
                        The Lab and Logistics Team will contact you with any needs for details and to provide you with updates on
                        your request.
                    </p>
                    <p>
                        If you need any further assistance with this request, you can contact:{' '}
                        <a href="mailto:LLORequests@Microsoft.com">LLORequests@Microsoft.com</a>.
                    </p>
                    <p>Click 'Create Request' on the left to create another request.</p>
                </div>
            );
            break;
        case 'failure':
            renderMessage = (
                <div className="message">
                    <div className="message-box severe-warning-background">
                        <div className="message-header severe-warning">Request Creation Failure</div>
                        <div className="message-box-content">The Request you submitted was not created successfully.</div>
                    </div>
                    <p>We apologize for the inconvenience; we know this is frustrating.</p>
                    <p>This failure has been logged and the support team has been alerted.</p>
                    <p>
                        If this is an emergency need, you can contact{' '}
                        <a href="mailto:LLORequests@Microsoft.com">LLORequests@Microsoft.com</a> directly. Else, we ask that you
                        wait a few minutes and try again.
                    </p>
                    <p>
                        If you encounter this issue a second time, please contact{' '}
                        <a href="mailto:DevicesHelp@Microsoft.com">DevicesHelp@Microsoft.com</a> and include (cut/paste) the
                        following information along with any additional details you feel would be helpful:
                    </p>
                    <div className="error-details">
                        <p>
                            <b>Application:</b> LLO Request Intake Application
                            <br />
                            <b>Issue:</b> Failure to successfully submit a new request
                            <br />
                            <b>Date Time:</b> {props.requestDateTime.toString()}
                            <br />
                            <b>AI ID:</b> {getClientId()}
                        </p>
                    </div>
                </div>
            );
            break;
    }

    return renderMessage;
};
export default message;
