import { IPersonaProps } from 'office-ui-fabric-react';
import * as React from 'react';
import { IRequestDto } from 'app/models/IRequestDto';

export function IsNullOrWhiteSpace(str: string) {
    return typeof str === 'undefined' || str == null || str.replace(/\s/g, '').length < 1;
}

export function convertToText(array: IPersonaProps[]) {
    const arr: string[] = [];
    array.map((people) => {
        arr.push(`${people.text} (${people.secondaryText})`);
        return null;
    });
    return arr;
}

export function getAttachmentGuid(url: string) {
    const params = url.split('/');
    const guidIndex = (params.indexOf('attachments') + 1);

    return params[guidIndex];
}

export function getAreaPath() {
    switch (process.env.REACT_APP_ENV.toLowerCase()) {
        case 'local':
            return process.env.REACT_APP_AREA_PATH_LOCAL;
        case 'dev':
            return process.env.REACT_APP_AREA_PATH_DEV;
        case 'uat':
            return process.env.REACT_APP_AREA_PATH_UAT;
        case 'prod':
            return process.env.REACT_APP_AREA_PATH_PROD;
    }
}

export function getPickListId() {
    switch (process.env.REACT_APP_ENV.toLowerCase()) {
        case 'local':
            return process.env.REACT_APP_SHIPPING_PICKLIST_DEV;
        case 'dev':
            return process.env.REACT_APP_SHIPPING_PICKLIST_DEV;
        case 'uat':
            return process.env.REACT_APP_SHIPPING_PICKLIST_DEV;
        case 'prod':
            return process.env.REACT_APP_SHIPPING_PICKLIST_PROD;
    }
}

export function getClientId() {
    switch (process.env.REACT_APP_ENV.toLowerCase()) {
        case 'local':
            return process.env.REACT_APP_DEV_CLIENT_ID;
        case 'dev':
            return process.env.REACT_APP_DEV_CLIENT_ID;
        case 'uat':
            return process.env.REACT_APP_UAT_CLIENT_ID;
        case 'prod':
            return process.env.REACT_APP_PROD_CLIENT_ID;
    }
}

export function convertFileSizeToString(fileSize: number) {
    if (fileSize > Math.pow(1024, 3)) {
        return (fileSize / Math.pow(1024, 3)).toFixed(2).toString() + ' GB';
    } else if (fileSize > Math.pow(1024, 2)) {
        return (fileSize / Math.pow(1024, 2)).toFixed(2).toString() + ' MB';
    } else {
        return (fileSize / 1024).toFixed(2).toString() + ' KB';
    }
}

export const renderFormSection = (title: string, fieldRows: JSX.Element[][]) => {
    return (
        <React.Fragment>
            <div className="ms-Grid-row section-header">
                {title}
            </div>
            {   
                fieldRows.map((fieldCols, sectionIndex) => {
                    return (
                        <div className="ms-Grid-row" key={`section${sectionIndex}`}>
                            {fieldCols.map((field, fieldIndex) => {
                                let classes = "ms-Grid-col";
                                let smCols: number;
                                fieldCols.length > 1 ? smCols = 6 : smCols = 12;
                                classes += ` ms-lg${12 / fieldCols.length} ms-md${12 / fieldCols.length} ms-sm${smCols}`;
                                return (
                                    <div className={classes} key={`section${sectionIndex}-field${fieldIndex}`}>
                                        {field}
                                    </div>    
                                );
                            })}
                        </div>
                    );
                })
            }
        </React.Fragment>
    );
};

export const abbreviateRequest = (request: IRequestDto) => {
    return {
        // Request Information
        category: request['Microsoft.Custom.RequestCategory'],
        type: request['Microsoft.Custom.Surface.RequestType'],
        requestor: request['Microsoft.Custom.Surface.RequestorName'],
        approver: request['Microsoft.Custom.QualityApproverName'],
        title: request['System.Title'],
        description: request['System.Description'],
        pickupLocation: request['Microsoft.Custom.PickupLocation'],
        dropoffLocation: request['Microsoft.Custom.DropLocation'],
        date: request['Microsoft.Custom.RequestedStartDate'],
        // Package Information
        numberOfPieces: request['Microsoft.Custom.NumberOfPieces'],
        palletized: request['Microsoft.Custom.Palletized'],
        weight: request['Microsoft.Custom.Weight'],
        dimensions: request['Microsoft.Custom.Dimensions'],
        // Shipping Information
        exportType: request['Custom.ExportType'],
        intendedUse: request['Microsoft.Custom.IntendedUse'],
        value: request['Microsoft.VSTS.Common.ValueArea'],
        reason: request['Microsoft.Custom.ExportReason'],
        confidential: request['Microsoft.Custom.Confidential'],
        condition: request['Microsoft.Custom.Condition'],
        battery: request['Microsoft.Custom.BatteryIncluded'],
        liquid: request['Microsoft.Custom.LiquidIncluded'],
        company: request['Microsoft.Custom.CompanyName'],
        address: request['Microsoft.Custom.ShipToAddress'],
        postalCode: request['Microsoft.Custom.PostalCode'],
        reciever: request['Microsoft.Custom.Surface.Customername'],
        recieverPhone: request['Microsoft.Custom.ReceiverPhone'],
        // Automatically set in ADO / Odin
        id: request['System.Id'],
        assignedTo: request['System.AssignedTo'],
        createdDate: request['System.CreatedDate'],
        workItemType: request['System.WorkItemType'],
        state: request['System.State'],
        createdBy: request['System.CreatedBy'],
        // Unused (fields taken out of form, but remain in ADO)
        contact: request['Microsoft.Custom.ContactName'],
        contactPhone: request['Microsoft.Custom.ContactPhone'],
    }
}