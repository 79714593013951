import { TextField, ChoiceGroup, IChoiceGroupOption } from 'office-ui-fabric-react';
import React from 'react';
import { IRequestDto, IRequestRelation } from 'app/models/IRequestDto';
import moment from 'moment';
import { renderFormSection, abbreviateRequest } from 'app/utils/helperFunctions';
import AttachmentsModal from 'app/components/AttachmentsModal/AttachmentsModal'
import { IUser } from 'app/models/IUser';
import { AppInsightsService } from 'app/utils/appInsights';

interface IRequestProps {
    requestDto: IRequestDto;
    requestRelation: IRequestRelation;
    currentUser: IUser;
}

const conditionOptions: IChoiceGroupOption[] = [
    { key: 'New', text: 'New' },
    { key: 'Used', text: 'Used' }
];

const truthyOptions: IChoiceGroupOption[] = [
    { key: 'yes', text: 'Yes' },
    { key: 'no', text: 'No' }
];

const appInsights = new AppInsightsService();

const ViewLogisticsRequest: React.FC<IRequestProps> = ({ requestDto, requestRelation, currentUser }) => {
    const request = abbreviateRequest(requestDto);

    appInsights.trackEvent(`${currentUser.userPrincipalName} viewing Logistics request #${request.id}`);

    const requestInformationInputs = [
        [
            <TextField
                label="Request Type"
                value={request.type}
                readOnly
            />,
            <TextField
                label="Requestor"
                value={request.requestor}
                readOnly
            />,
            <TextField
                label="Approver"
                value={request.approver}
                readOnly
            />
        ],
        [
            <TextField
                label="Title"
                value={request.title}
                readOnly
            />
        ],
        [
            <TextField
                label="Request Description"
                value={request.description}
                multiline
                readOnly
                rows={5}
            />
        ],
        [
            <TextField
                label="Drop Off Location"
                value={request.dropoffLocation}
                readOnly
            />,
            <TextField
                label="Pickup Date"
                value={moment(new Date(request.date).toISOString()).format(
                    'MMM DD, YYYY'
                )}
                readOnly
            />,
            <React.Fragment />
        ]
    ];

    const packageInformationInputs = [
        [
            <TextField
                label="Number of Pieces"
                value={request.numberOfPieces?.toString()}
                readOnly
            />,
            <ChoiceGroup
                label="Palletized?"
                className="choiceGroup-inline-flex"
                options={truthyOptions}
                selectedKey={request.palletized ? 'yes' : 'no'}
            />,
            <React.Fragment />
        ],
        [
            <TextField
                label="Weight"
                value={request.weight?.toString()}
                readOnly
            />,
            <TextField
                label="Dimensions"
                value={request.dimensions}
                readOnly
            />,
            <React.Fragment />
        ]
    ];

    const shippingInformationInputs = [
        [
            <TextField
                label="Shipping Type"
                value={request.exportType}
                readOnly
            />,
            <TextField
                label="Intended Use"
                value={request.intendedUse}
                readOnly
            />,
            <TextField
                label="Retail/Market Value Per Piece"
                value={request.value}
                readOnly
            />
        ],
        [
            <ChoiceGroup
                label="Confidential?"
                className="choiceGroup-inline-flex"
                selectedKey={request.confidential ? 'yes' : 'no'}
                options={truthyOptions}
            />,
            <ChoiceGroup
                label="Condition"
                selectedKey={request.condition}
                className="choiceGroup-inline-flex"
                options={conditionOptions}
            />,
            <React.Fragment />
        ],
        [
            <ChoiceGroup
                label="Battery Included?"
                className="choiceGroup-inline-flex"
                selectedKey={request.battery ? 'yes' : 'no'}
                options={truthyOptions}
            />,
            <ChoiceGroup
                className="choiceGroup-inline-flex"
                label="Liquid Included?"
                selectedKey={request.liquid ? 'yes' : 'no'}
                options={truthyOptions}
            />,
            <React.Fragment />
        ],
        [
            <TextField
                label="Company Name"
                value={request.company}
                readOnly
            />
        ],
        [
            <TextField
                label="Address" 
                value={request.address}
                readOnly
            />
        ],
        [
            <TextField
                label="Postal Code"
                value={request.postalCode}
                readOnly
            />,
            <TextField
                label="Receiver Name"
                value={request.reciever}
                readOnly
            />,
            <TextField
                label="Receiver Phone Number"
                value={request.recieverPhone}
                readOnly
            />
        ],
        [
            <AttachmentsModal currentUser={currentUser} requestRelation={requestRelation} />
        ]
    ];

    return (
        <React.Fragment>
            {renderFormSection('Request Information', requestInformationInputs)}
            {renderFormSection('Package Information', packageInformationInputs)}
            {renderFormSection('Shipping Information', shippingInformationInputs)}
        </React.Fragment>
    )
};

export default ViewLogisticsRequest;