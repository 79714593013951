import { TextField } from 'office-ui-fabric-react';
import * as React from 'react';
import { IRequestDto, IRequestRelation } from 'app/models/IRequestDto';
import moment from 'moment';
import { renderFormSection, abbreviateRequest } from 'app/utils/helperFunctions';
import AttachmentsModal from 'app/components/AttachmentsModal/AttachmentsModal';
import { IUser } from 'app/models/IUser';
import { AppInsightsService } from 'app/utils/appInsights';

interface IRequestProps {
    requestDto: IRequestDto;
    requestRelation: IRequestRelation;
    currentUser: IUser;
}

const appInsights = new AppInsightsService();

const ViewLabRequest: React.FC<IRequestProps> = ({ requestDto, requestRelation, currentUser }) => {
    const request = abbreviateRequest(requestDto);

    appInsights.trackEvent(`${currentUser.userPrincipalName} viewing Lab request #${request.id}`);

    const requestInformationInputs = [
        [
            <TextField
                label="Request Type"
                value={request.type}
                readOnly
            />,
            <TextField
                label="Requestor"
                value={request.requestor}
                readOnly
            />,
            <TextField
                label="Approver"
                value={request.approver}
                readOnly
            />
        ],
        [
            <TextField
                label="Title"
                value={request.title}
                readOnly
            />
        ],
        [
            <TextField
                label="Request Description"
                value={request.description}
                multiline
                readOnly
                rows={5}
            />
        ],
        [
            <TextField
                label="Location"
                value={request.pickupLocation}
                readOnly
            />,
            <TextField
                label="Requested Start Date"
                value={moment(new Date(request.date).toISOString()).format('MMM DD, YYYY')}
                readOnly
            />,
            <React.Fragment />
        ],
        [
            <AttachmentsModal currentUser={currentUser} requestRelation={requestRelation} />
        ]
    ];

    return (
        <React.Fragment>
            {renderFormSection('Request Information', requestInformationInputs)}
        </React.Fragment>
    )
};

export default ViewLabRequest;