import { AppInsights } from 'applicationinsights-js';

export class AppInsightsService {
    constructor() {
        if (AppInsights) {
            AppInsights.downloadAndSetup({
                instrumentationKey: this.getAppInsightsEnvironment()
            });
        }
    }

    public logException(error: Error, properties?: any, measurements?: any) {
        if (AppInsights) {
            console.error(error.message);
            AppInsights.trackException(error, '', properties, measurements);
            AppInsights.flush();
        }
    }

    public trackPageView() {
        if (AppInsights) {
            AppInsights.trackPageView();
        }
    }

    public trackEvent(name: string, itemType?: string, fileCount?: number) {
        if (AppInsights) {
            const _properties: Map<string, string> = new Map<string, string>();
            const _measurements: Map<string, number> = new Map<string, number>();

            _properties.set('itemType', itemType);
            _measurements.set('fileCount', fileCount);

            AppInsights.trackEvent(name, _properties, _measurements);
        }
    }

    private getAppInsightsEnvironment() {
        switch (process.env.REACT_APP_ENV.toLowerCase()) {
            case 'local':
                return process.env.REACT_APP_APPINSIGHTS_KEY_DEV;
            case 'dev':
                return process.env.REACT_APP_APPINSIGHTS_KEY_DEV;
            case 'uat':
                return process.env.REACT_APP_APPINSIGHTS_KEY_UAT;
            case 'prod':
                return process.env.REACT_APP_APPINSIGHTS_KEY_PROD;
        }
    }
}
