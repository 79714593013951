export interface IUser {
    // Properties present in MS Graph v1.0 and Beta users
    businessPhones?: Array<string>;
    displayName: string;
    givenName: string;
    jobTitle?: string;
    mail?: string;
    mobilePhone?: string;
    officeLocation?: string;
    preferredLanguage?: string;
    surname?: string;
    userPrincipalName?: string;
    id: string;

    // Properties only present in MS Graph Beta users
    aboutMe?: string;
    accountEnabled?: boolean;
    ageGroup?: string;
    assignedLicenses?: Array<any>;
    assignedPlans?: Array<any>;
    birthday?: any;
    city?: string;
    companyName?: string;
    consentProvidedForMinor?: string;
    country?: string;
    createdDateTime?: any;
    creationType?: string;
    deletedDateTime?: any;
    department?: string;
    employeeId?: string;
    externalUserState?: string;
    externalUserStateChangeDateTime?: string;
    faxNumber?: string;
    hireDate?: any;
    identities?: Array<any>;
    imAddresses?: Array<string>;
    interests?: Array<string>;
    isResourceAccount?: boolean;
    lastPasswordChangeDateTime?: any;
    legalAgeGroupClassification?: string;
    licenseAssignmentStates?: Array<any>;
    mailboxSettings?: any;
    mailNickname?: string;
    mySite?: string;
    onPremisesDistinguishedName?: string;
    onPremisesDomainName?: string;
    onPremisesExtensionAttributes?: any;
    onPremisesImmutableId?: string;
    onPremisesLastSyncDateTime?: any;
    onPremisesProvisioningErrors?: Array<any>;
    onPremisesSamAccountName?: string;
    onPremisesSecurityIdentifier?: string;
    onPremisesSyncEnabled?: boolean;
    onPremisesUserPrincipalName?: string;
    otherMails?: Array<string>;
    passwordPolicies?: string;
    passwordProfile?: any;
    pastProjects?: Array<string>;
    postalCode?: string;
    preferredDataLocation?: string;
    preferredName?: string;
    provisionedPlans?: Array<any>;
    proxyAddresses?: Array<string>;
    refreshTokensValidFromDateTime?: any;
    responsibilities?: Array<string>;
    schools?: Array<string>;
    showInAddressList?: boolean;
    signInSessionsValidFromDateTime?: any;
    skills?: Array<string>;
    signInActivity?: any;
    state?: string;
    streetAddress?: string;
    usageLocation?: string;
    userType?: string;

    // Allow for extra properties not listed above
    [propName: string]: any;
}

export interface IUserManager {
    displayName?: string;
    givenName?: string;
    jobTitle?: string;
    mail?: string;
    mobilePhone?: string;
    officeLocation?: string;
    preferredLanguage?: string;
    surname?: string;
    userPrincipalName?: string;
}

export const Default_Manager: IUserManager = {
    userPrincipalName: ''
};

export interface IGroupPermission {
    groupId: number;
    isSecurityAdmin?: boolean;
    isProgramManager?: boolean;
    isEngagementPM?: boolean;
    isLeadership?: boolean;
    isGroupAdmin?: boolean;
}

export const EMPTY_USER: IUser = {
    displayName: '',
    givenName: '',
    surname: '',
    userPrincipalName: '',
    id: '',
    mail: '',
    jobTitle: ''
};
