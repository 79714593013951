import { MsalAuthProvider, LoginType } from 'react-aad-msal';
import 'regenerator-runtime/runtime';
import { getClientId } from 'app/utils/helperFunctions';

// Msal Configurations
const config = {
    auth: {
        authority: `https://login.microsoftonline.com/${process.env.REACT_APP_TENANT_ID}`,
        clientId: getClientId(),
        redirectUri: window.location.origin + '/',
        postLogoutRedirectUri: window.location.origin + '/'
    },
    cache: {
        cacheLocation: 'localStorage' as 'localStorage',
        storeAuthStateInCookie: true
    }
};

// Authentication Parameters
const authenticationParameters = {
    scopes: ['User.Read', 'User.ReadBasic.All', 'profile', 'openid']
};

// Options
const options = {
    loginType: LoginType.Redirect,
    tokenRefreshUri: window.location.origin + '/auth.html'
};

export const authProvider = new MsalAuthProvider(config, authenticationParameters, options);
