import * as React from 'react';
import { IUser } from 'app/models/IUser';
import { Spinner, Fabric, Toggle, TextField, Link, PrimaryButton } from 'office-ui-fabric-react';
import { DetailsList, DetailsListLayoutMode, SelectionMode, IColumn } from 'office-ui-fabric-react/lib/DetailsList';
import { OdinService } from 'app/utils/odinService';
import { refreshTokens } from 'app/utils/httpService';
import { IRequestDto, IRequestRelation, IFileResponseMap } from 'app/models/IRequestDto';
import moment from 'moment';
import ViewLogisticsRequest from 'app/components/AllRequests/ViewRequest/ViewLogisticsRequest';
import ViewLabRequest from 'app/components/AllRequests/ViewRequest/ViewLabRequest';
import { abbreviateRequest } from 'app/utils/helperFunctions';
import { AppInsightsService } from 'app/utils/appInsights';

interface IAllRequestsState {
    columns: IColumn[];
    allMyRequests: IRequestDto[];
    allRequestAttachments: IRequestRelation[];
    filteredRequests: IRequestDto[];
    isLoading: boolean;
    isViewingRequest: boolean;
    viewRequestSelected: IRequestDto;
    showClosed: boolean;
}

interface IAllRequestsProps {
    currentUser: IUser;
    refreshNav: (string) => void;
}

const odinService = new OdinService();
const appInsights = new AppInsightsService();

const spinnerStyles = {
    circle: {
        height: 150,
        width: 150,
        borderWidth: 10
    }
};

export default class AllRequests extends React.Component<IAllRequestsProps, IAllRequestsState> {
    _isMounted = false;

    constructor(props: IAllRequestsProps) {
        super(props);

        const columns: IColumn[] = [
            {
                key: 'id',
                name: 'ID',
                fieldName: 'ID',
                minWidth: 30,
                maxWidth: 50,
                isRowHeader: true,
                isResizable: true,
                isMultiline: true,
                isCollapsable: false,
                isSorted: true,
                isSortedDescending: false,
                onColumnClick: this.onColumnClick,
                sortAscendingAriaLabel: 'Sorted A to Z',
                sortDescendingAriaLabel: 'Sorted Z to A',
                data: 'number'
            },
            {
                key: 'created',
                name: 'Created',
                fieldName: 'Created',
                minWidth: 80,
                maxWidth: 100,
                isRowHeader: true,
                isResizable: true,
                isCollapsable: false,
                isMultiline: true,
                data: 'string',
                onColumnClick: this.onColumnClick
            },
            {
                key: 'title',
                name: 'Title',
                fieldName: 'Title',
                minWidth: 100,
                maxWidth: 150,
                isRowHeader: true,
                isResizable: true,
                isCollapsible: false,
                isMultiline: true,
                data: 'string',
                onColumnClick: this.onColumnClick
            },
            {
                key: 'category',
                name: 'Category',
                fieldName: 'Category',
                minWidth: 50,
                maxWidth: 100,
                isRowHeader: true,
                isResizable: true,
                isMultiline: true,
                isCollapsible: false,
                data: 'string',
                onColumnClick: this.onColumnClick
            },
            {
                key: 'type',
                name: 'Type',
                fieldName: 'Type',
                minWidth: 80,
                maxWidth: 100,
                isRowHeader: true,
                isResizable: true,
                isCollapsible: false,
                isMultiline: true,
                data: 'string',
                onColumnClick: this.onColumnClick
            },
            {
                key: 'status',
                name: 'Status',
                fieldName: 'Status',
                minWidth: 80,
                maxWidth: 120,
                isRowHeader: true,
                isResizable: true,
                isCollapsible: false,
                isMultiline: true,
                data: 'string',
                onColumnClick: this.onColumnClick
            },
            {
                key: 'assignedTo',
                name: 'Assigned To',
                fieldName: 'AssignedTo',
                minWidth: 80,
                maxWidth: 120,
                isRowHeader: true,
                isResizable: true,
                isCollapsible: false,
                isMultiline: true,
                data: 'string',
                onColumnClick: this.onColumnClick
            }
        ];
        this.props.refreshNav('AllRequests');
        this.state = {
            allMyRequests: [],
            allRequestAttachments: [],
            filteredRequests: [],
            columns: columns,
            isLoading: false,
            isViewingRequest: false,
            viewRequestSelected: undefined,
            showClosed: false
        };
    }

    public async componentDidMount() {
        this._isMounted = true;
        this.setState({
            isLoading: true
        });

        await refreshTokens()
            .then(() => {
                appInsights.trackPageView();
                appInsights.trackEvent(`${this.props.currentUser.userPrincipalName} querying for all requests`);

                const startTime = performance.now();
                odinService.getWorkItems()
                    .then((workItems) => {
                        const myRequestsFromAdo: IRequestDto[] = [];
                        const requestAttachments: IRequestRelation[] = [];
                        workItems.forEach((workItem) => {
                            myRequestsFromAdo.push(workItem.fields as IRequestDto);
                            requestAttachments.push({
                                requestId: workItem.fields['System.Id'],
                                attachmentMaps: workItem.relations?.map((relation) => {
                                    var map: IFileResponseMap = {
                                        fileName: relation.attributes['name'],
                                        fileId: relation.url
                                    }
                                    return map;
                                })
                                });
                        });
                        this._isMounted && this.setState({
                            allMyRequests: myRequestsFromAdo,
                            filteredRequests: myRequestsFromAdo,
                            allRequestAttachments: requestAttachments
                        });
                        this._isMounted && this.setState({ isLoading: false });
                    })
                    .catch((error) => {
                        appInsights.logException(error);
                        this._isMounted && this.setState({ isLoading: false });
                    });
                const endTime = performance.now();
                appInsights.trackEvent(`All requests response time: ${(endTime - startTime)}`);
            })
            .catch((error) => {
                appInsights.logException(error);
                this._isMounted && this.setState({ isLoading: false });
            });
    }

    public async componentWillUnmount() {
        this._isMounted = false;
    }

    public render() {
        const { columns, showClosed, filteredRequests } = this.state;

        return (
            <Fabric>
                <div id="RequestPage">
                    <h2>My Requests</h2>
                    {this.state.isViewingRequest ? (
                        <div>
                            <PrimaryButton
                                text="Close"
                                style={{ padding: 10 }}
                                onClick={this.onCloseRequest}
                            />
                            {this.renderRequest()}
                        </div>
                    ) : (
                        <div>
                            <div>
                                <Toggle
                                    label="Show Closed Requests"
                                    checked={showClosed}
                                    onChange={(event, checked) => { this.setState({ showClosed: checked })}}
                                    onText="On"
                                    offText="Off"
                                />
                                <TextField label="Filter by Title:" onChange={this.onChangeFilter} />
                            </div>
                            <DetailsList
                                items={ showClosed ? filteredRequests : filteredRequests.filter(request => request['System.State'] !== "Closed")}
                                columns={columns}
                                onRenderItemColumn={renderItemColumn}
                                selectionMode={SelectionMode.none}
                                layoutMode={DetailsListLayoutMode.justified}
                                isHeaderVisible={true}
                                onItemInvoked={this.onRequestInvoked}
                                constrainMode={1}
                            />
                        </div>
                    )}

                    {this.state.isLoading ? (
                        <div className="loadingScreen">
                            <Spinner styles={spinnerStyles} />
                            <label aria-label="Fetching Requests">Loading...</label>
                        </div>
                    ) : this.state.isViewingRequest ? <React.Fragment /> : (
                        <p>Double-click table row to open request details.</p>
                    )}
                </div>
            </Fabric>
        );
    }

    private onCloseRequest = () => {
        this.setState({ isViewingRequest: false });
        this.props.refreshNav('AllRequests');
    };

    private renderRequest = () => {
        switch (this.state.viewRequestSelected['Microsoft.Custom.RequestCategory']) {
            case 'Logistics':
                return <ViewLogisticsRequest currentUser={this.props.currentUser} requestRelation={this.getRequestRelation()} requestDto={this.state.viewRequestSelected} />;
            case 'Lab':
            default:
                return <ViewLabRequest currentUser={this.props.currentUser} requestRelation={this.getRequestRelation()} requestDto={this.state.viewRequestSelected} />;
        }
    };

    private getRequestRelation = () => {
        return this.state.allRequestAttachments.find(relation => relation.requestId === this.state.viewRequestSelected["System.Id"]);
    }

    private onRequestInvoked = (item: IRequestDto) => {
        const requestSelected = this.state.allMyRequests.filter((request) => request['System.Id'] === item['System.Id'])[0];

        this.setState({
            viewRequestSelected: requestSelected,
            isViewingRequest: true
        });
        switch (requestSelected["Microsoft.Custom.RequestCategory"]) {
            case 'Logistics':
                this.props.refreshNav('ViewLogisticsRequest');
                break;
            case 'Lab':
                this.props.refreshNav('ViewLabRequest');
                break;
        }
    };

    private onChangeFilter = (ev: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, text: string): void => {
        this.setState({
            filteredRequests:
                text ? this.state.allMyRequests.filter((request) => {
                    return request['System.Title'].toLowerCase().indexOf(text.toLowerCase()) > -1
                }) : this.state.allMyRequests
        });
    };

    private onColumnClick = (ev: React.MouseEvent<HTMLElement>, column: IColumn): void => {
        const { columns, allMyRequests } = this.state;
        const newColumns: IColumn[] = columns.slice();
        const currColumn: IColumn = newColumns.filter((currCol) => column.key === currCol.key)[0];
        newColumns.forEach((newCol: IColumn) => {
            if (newCol === currColumn) {
                currColumn.isSortedDescending = !currColumn.isSortedDescending;
                currColumn.isSorted = true;
            } else {
                newCol.isSorted = false;
                newCol.isSortedDescending = true;
            }
        });
        const newItems = copyAndSort(allMyRequests, currColumn.fieldName!, currColumn.isSortedDescending);
        this.setState({
            columns: newColumns,
            allMyRequests: newItems
        });
    };
}

function renderItemColumn(requestDto: IRequestDto, index: number, column: IColumn) {
    const request = abbreviateRequest(requestDto);
    switch (column.key) {
        case 'id':
            return request.id;
        case 'created':
            return moment(request.createdDate).format('MMM DD, YYYY');
        case 'title':
            return request.title;
        case 'category':
            return request.category;
        case 'type':
            return request.type;
        case 'status':
            return request.state;
        case 'assignedTo':
            const content = request.assignedTo;
            if (!content) {
                return '';
            } else if (content.includes('pending')) {
                return 'Pending';
            }
            const email = content.split('<')[1].replace('>', ' ');
            const mailTo = 'mailto:' + email;
            return <Link href={mailTo}>{content}</Link>;
    }
}

function copyAndSort<T>(items: T[], columnKey: string, isSortedDescending?: boolean): T[] {
    const key = columnKey as keyof T;
    return items.slice(0).sort((a: T, b: T) => ((isSortedDescending ? a[key] < b[key] : a[key] > b[key]) ? 1 : -1));
}
