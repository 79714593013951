import axios, { AxiosRequestConfig } from 'axios';
import { getClientId } from 'app/utils/helperFunctions';
import { authProvider } from 'app/utils/authProvider';

const httpService = axios.create({
    baseURL: 'https://graph.microsoft.com/',
    headers: {
        'Content-Type': 'application/json;charset=UTF-8'
    }
});

export const refreshTokens = async (config?: AxiosRequestConfig) => {
    // Id Token used for Odin calls does not get refreshed in the acquireTokenSilent call
    const refreshIdToken = await authProvider.getIdToken();

    await authProvider
        .acquireTokenSilent({
            scopes: ['User.Read', 'User.ReadBasic.All', 'profile', 'openid']
        })
        .then((token) => {
                // Entering MSAL tokens where ADAL expects them in local storage, in order to use OdinClient (which uses ADAL)
                localStorage.setItem('adal.access.token.key' + getClientId(), refreshIdToken.idToken.rawIdToken);
                localStorage.setItem('adal.expiration.key' + getClientId(), new Date(token.expiresOn).getTime().toString());
                localStorage.setItem('adal.session.state', token.accountState);
                localStorage.setItem('adal.login.request', window.location.origin + '/auth.html');
                localStorage.setItem('adal.token.keys', getClientId() + '|');
                localStorage.setItem('adal.idtoken', refreshIdToken.idToken.rawIdToken);
                localStorage.setItem('adal.token.renew.status' + getClientId(), 'Completed');

                if (config) {
                    config.headers.Authorization = `Bearer ${token.accessToken}`;
                }
            })
            .catch((error) => {
                if (error.errorCode === 'interaction_required') {
                    authProvider.acquireTokenRedirect({
                        scopes: ['User.Read', 'User.ReadBasic.All', 'profile', 'openid']
                    });
                }
            });

    return config;
};

httpService.interceptors.request.use(refreshTokens, (error: any) => {
    return Promise.reject(error);
});

export default httpService;
