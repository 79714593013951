import {
    IDropdownOption,
    PrimaryButton,
    TextField,
    DatePicker,
    TeachingBubble,
    IButtonProps
} from 'office-ui-fabric-react';
import * as React from 'react';
import { RequestType } from 'app/models/Constants/enums';
import { OdinService } from 'app/utils/odinService';
import { AppInsightsService } from 'app/utils/appInsights';
import {
    getAreaPath,
    IsNullOrWhiteSpace,
    convertFileSizeToString,
    renderFormSection
} from 'app/utils/helperFunctions';
import DropDownExtension from 'app/components/DropDown/DropDown';
import { DropZone, IDocument } from 'app/components/DropZone/DropZone';
import { IUser, IUserManager } from 'app/models/IUser';
import { FileParameter, LinkType, WorkItemLinkDto } from 'odinclient/build/main/lib/generated';
import { refreshTokens } from 'app/utils/httpService';

const odinService = new OdinService();
const appInsights = new AppInsightsService();

interface IRequestProps {
    requestCategorySelected: string;
    messageTrigger?: (result: string, message: string) => void;
    isLoading?: (isLoading: boolean) => void;
    currentUser: IUser;
    currentUserManager: IUserManager;
}

interface IRequestState {
    // Request Information
    requestTypeOptions: Array<string>;
    requestTypeSelected: string;
    requestTitle: string;
    approver: string;
    requestDescription: string;
    location: string;
    requestedStartDate: Date;
    // Attachments
    documentsForUpload: IDocument[];
    // Other Misc State
    currentAreaPath: string;
    isWarning: boolean;
    // Teaching Bubble Notification
    teachingBubbleVisible: boolean;
    teachingBubbleTarget: string;
    teachingBubbleMessage: string;
}

const initState = {
    // Request Information
    requestTypeOptions: ['Move Request', 'Maintenance Issue', 'Equipment Request', 'Lab Modifications'],
    requestTypeSelected: undefined,
    requestTitle: undefined,
    requestDescription: undefined,
    location: undefined,
    requestedStartDate: undefined,
    // Attachments
    documentsForUpload: [],
    // Other Misc State
    currentAreaPath: getAreaPath(),
    isWarning: false,
    // Teaching Bubble Notification
    teachingBubbleVisible: false,
    teachingBubbleTarget: undefined,
    teachingBubbleMessage: undefined
};

export default class LabForm extends React.Component<IRequestProps, IRequestState> {
    constructor(props: IRequestProps) {
        super(props);
        this.state = {
            ...initState,
            approver: this.props.currentUserManager.userPrincipalName
        };
        appInsights.trackPageView();
    }

    private isFormValid = () => {
        return (
            !IsNullOrWhiteSpace(this.state.requestTitle) &&
            this.props.currentUser !== undefined &&
            !IsNullOrWhiteSpace(this.state.requestDescription) &&
            !IsNullOrWhiteSpace(this.state.location) &&
            !IsNullOrWhiteSpace(this.state.requestTypeSelected) &&
            this.state.requestedStartDate !== undefined &&
            !IsNullOrWhiteSpace(this.state.approver)
        );
    };

    private bubbleButtonProps = (): IButtonProps => {
        const teachingBubbleButtonProps: IButtonProps = {
            children: 'Dismiss',
            onClick: () => this.setState({
                teachingBubbleVisible: false,
                teachingBubbleTarget: undefined,
                teachingBubbleMessage: undefined
            })
        }

        return teachingBubbleButtonProps;
    }

    private handleRequestedStartDateChange = (date) => {
        this.setState({ requestedStartDate: date });
    };

    private handleDropDownChange = (event: React.FormEvent<HTMLDivElement>, fieldName: string, fieldValue?: IDropdownOption) => {
        const partialState = {};
        partialState[fieldName] = fieldValue.text;
        this.setState(partialState);
    };

    private handleTextFieldChange = (
        event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
        fieldName: string,
        fieldValue?: string,
    ) => {
        const partialState = {};
        partialState[fieldName] = fieldValue;
        this.setState(partialState);
    };

    private handleDropFile = (droppedFiles: File[], rejectedFiles: File[]) => {
        const timestamp = new Date();
        let newlyAcceptedDocuments = droppedFiles.map((file) => {
            const doc: IDocument = {
                key: file.name + timestamp.getSeconds().toString() + timestamp.getMilliseconds().toString(),
                fileSize: convertFileSizeToString(file.size),
                file: file
            };
            return doc;
        });
        this.setState({ documentsForUpload: [...this.state.documentsForUpload, ...newlyAcceptedDocuments] });
        if (rejectedFiles.length > 0) {
            this.setState({
                teachingBubbleVisible: true,
                teachingBubbleTarget: '#DropZone',
                teachingBubbleMessage: 'Files may not exceed 100MB.'
            });
        }
    };

    private handleRemoveFile = (removedFileKey: string) => {
        this.setState({
            documentsForUpload: this.state.documentsForUpload.filter((file) => {
                return file.key !== removedFileKey;
            })
        });
    };

    private handleFormSubmissionError(appInsights: AppInsightsService, error) {
        appInsights.logException(error);
        appInsights.trackEvent(`${this.props.currentUser.userPrincipalName} failed to submit a Lab Request. Details: ${error}`);
        this.props.isLoading(false);
        this.props.messageTrigger(null, 'failure');
    }

    private handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        this.props.isLoading(true);
        refreshTokens()
            .then(() => {
                appInsights.trackEvent(`${this.props.currentUser.userPrincipalName} submitting a Lab Request`);
                const requestStart = performance.now();
                odinService
                    .submitWorkItem(this.getWorkitemFields())
                    .then(_workItemId => {
                        appInsights.trackEvent(`${this.props.currentUser.userPrincipalName} created work item #${_workItemId}`);
                        if (this.state.documentsForUpload.length > 0) {
                            let files: FileParameter[] = this.state.documentsForUpload.map(doc => {
                                const fileParameter: FileParameter = {
                                    fileName: doc.file.name,
                                    data: doc.file
                                };
                                return fileParameter;
                            });
                            appInsights.trackEvent(`Attaching files to work item #${_workItemId}`);
                            const fileUploadStart = performance.now();
                            odinService
                                .uploadAttachments(files)
                                .then(result => {
                                    let links: WorkItemLinkDto[] = [];
                                    result.forEach(AttachmentDto => {
                                        const workItemLink = {
                                            id: AttachmentDto.id,
                                            type: LinkType.AttachedFile,
                                            url: AttachmentDto.url,
                                            comment: 'Attached via LLO Request Intake',
                                            name: AttachmentDto.fileName,
                                            workItemId: _workItemId,
                                            init: null,
                                            toJSON: null
                                        };
                                        links.push(workItemLink);
                                    });
                                    odinService
                                        .linkAttachmentsToWorkItem(links)
                                        .then(() => {
                                            this.props.isLoading(false);
                                            this.props.messageTrigger(_workItemId, 'success');
                                            appInsights.trackEvent(`Work item #${_workItemId} created`, RequestType.Lab);
                                        })
                                        .catch(error => {
                                            appInsights.logException(error);
                                            this.props.isLoading(false);
                                            this.props.messageTrigger(_workItemId, 'failure');
                                            this.handleFormSubmissionError(appInsights, error);
                                        });
                                })
                                .catch(error => {
                                    appInsights.trackEvent(`Failed to upload attachments for work item #${_workItemId}`);
                                    appInsights.logException(error);
                                    this.props.isLoading(false);
                                    this.props.messageTrigger(_workItemId, 'failure');
                                    this.handleFormSubmissionError(appInsights, error);
                                });
                            const fileUploadEnd = performance.now();
                            appInsights.trackEvent(`File upload for work item #${_workItemId} response time: ${(fileUploadEnd - fileUploadStart)}`);
                        } else {
                            this.props.isLoading(false);
                            this.props.messageTrigger(_workItemId, 'success');
                            appInsights.trackEvent(`Lab work item #${_workItemId} created`, RequestType.Lab);
                        }
                    })
                    .catch(error => {
                        appInsights.trackEvent(`${this.props.currentUser.userPrincipalName} failed to submit a Lab request`);
                        appInsights.logException(error);
                        this.props.isLoading(false);
                        this.props.messageTrigger(null, 'failure');
                        this.handleFormSubmissionError(appInsights, error);
                    });
                const requestEnd = performance.now();
                appInsights.trackEvent(`Lab request submit response time: ${(requestEnd - requestStart)}`);
            })
            .catch(error => {
                appInsights.logException(error);
                this.props.isLoading(false);
                this.props.messageTrigger(null, 'failure');
                this.handleFormSubmissionError(appInsights, error);
            });
        event.preventDefault();
    };

    private getWorkitemFields() {
        return {
            "System.AreaPath": this.state.currentAreaPath,
            'Microsoft.Custom.RequestCategory': this.props.requestCategorySelected,
            'Microsoft.Custom.Surface.RequestType': this.state.requestTypeSelected,
            'System.Title': this.state.requestTitle,
            'System.Description': this.state.requestDescription,
            'System.AssignedTo': 'pending@microsoft.com',
            'Microsoft.Custom.PickupLocation': this.state.location,
            'Microsoft.Custom.RequestedStartDate': this.state.requestedStartDate,
            'Microsoft.Custom.QualityApproverName': this.state.approver,
            'Microsoft.Custom.Surface.RequestorName': this.props.currentUser.userPrincipalName
        };
    }

    public render() {
        const requestInformationInputs = [
            [
                <DropDownExtension
                    label="Request Type"
                    placeholder="Select Request Type"
                    dropDownOptions={this.state.requestTypeOptions}
                    required={true}
                    onChange={(event, newValue) => this.handleDropDownChange(event, 'requestTypeSelected', newValue)}
                />,
                <TextField
                    label="Requestor"
                    iconProps={{ iconName: 'ProfileSearch' }}
                    value={this.props.currentUser.displayName}
                    disabled
                    required
                    readOnly
                />,
                <TextField
                    label="Approver"
                    placeholder="Enter Approvers"
                    defaultValue={this.state.approver}
                    onChange={(event, newValue) => this.handleTextFieldChange(event, 'approver', newValue)}
                    required
                />
            ],
            [
                <TextField
                    label="Title"
                    onChange={(event, newValue) => this.handleTextFieldChange(event, 'requestTitle', newValue)}
                    placeholder="Title your request"
                    required
                />
            ],
            [
                <TextField
                    label="Request Description"
                    onChange={(event, newValue) => this.handleTextFieldChange(event, 'requestDescription', newValue)}
                    placeholder="Describe your request in as much detail as possible"
                    multiline
                    rows={5}
                    autoAdjustHeight
                    required
                />
            ],
            [
                <TextField
                    label="Pickup Location"
                    onChange={(event, newValue) => this.handleTextFieldChange(event, 'location', newValue)}
                    placeholder="Building or Lab Location"
                    required
                />,
                <DatePicker
                    value={this.state.requestedStartDate}
                    label="Requested Start Date"
                    onSelectDate={this.handleRequestedStartDateChange}
                    placeholder="Select date"
                    minDate={new Date()}
                    isRequired
                />,
                <React.Fragment>
                    {
                        this.state.teachingBubbleVisible
                            ? <TeachingBubble
                                target={this.state.teachingBubbleTarget}
                                primaryButtonProps={this.bubbleButtonProps()}
                                onDismiss={() => this.setState({ teachingBubbleVisible: false, teachingBubbleMessage: undefined, teachingBubbleTarget: undefined })}
                            >{this.state.teachingBubbleMessage}</TeachingBubble>
                            : null
                    }
                </React.Fragment>
            ]
        ];
    
        const attachmentsInputs = [
            [
                <DropZone
                    _handleDropFile={this.handleDropFile}
                    _handleRemoveFile={this.handleRemoveFile}
                    acceptedDocuments={this.state.documentsForUpload}
                />
            ]
        ];

        return (
            <form onSubmit={this.handleSubmit}>
                {renderFormSection('Request Information', requestInformationInputs)}
                {renderFormSection('Attachments', attachmentsInputs)}
                <div className="ms-Grid-row">
                    <PrimaryButton
                        type="submit"
                        text="Submit Request"
                        style={{marginTop: 15, padding: 10}}
                        disabled={!this.isFormValid()}
                    />
                </div>
            </form>
        )
    };
}